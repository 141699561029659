/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAi5cHwFZFiIbES2lSHNV4-l22GgqEZll0",
  "appId": "1:439770915062:web:9e686dcdd2eb6b778dccd7",
  "authDomain": "schooldog-i-oglethorpe-ga.firebaseapp.com",
  "measurementId": "G-963VJP2QJY",
  "messagingSenderId": "439770915062",
  "project": "schooldog-i-oglethorpe-ga",
  "projectId": "schooldog-i-oglethorpe-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-oglethorpe-ga.appspot.com"
}
